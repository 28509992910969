.better-business-part {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    padding: 0 50px;
    background-image: url("../../assets/images/betterbusiness.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    margin-top: 100px;
}

.left-side p:first-child {
    /* position: absolute; */
    top: -10vh;
    left: 8vw;
    font-size: 50px;
    font-weight: 800;
    line-height: 83px;
    text-align: left;

}

.left-side span {
    color: #7642E3;
    text-shadow: 2px 2px 2px black;
}
.containerDigigo{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.left-side p:nth-child(2) {
    /* position: absolute; */
   
    width: 100%;
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
    text-align: left;
    color: black;

}

.letuciycelovek {
    /* position: absolute; */
   width: 100%;
   height: 100%;
    top: -25vh;
    right: 1vw;

}

.left-side button {
    cursor: pointer;
    position: absolute;
    top: 50vh;
    left: 8vw;
    width: 170px;
    height: 60px;
    background-color: white;
    border-radius: 20px;
    border: none;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 800;
    line-height: 34px;
    text-align: center;
    color: #7642E3;
}

.services-home-page p:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 50px;
    font-weight: 800;
    line-height: 83px;
    text-align: left;
}

.services-home-page span {
    margin-right: 10px;
    color: #7642E3;
}


.service-box p:first-child {

    font-family: Montserrat;
    font-size: 25px;
    font-weight: 800;
    line-height: 47px;
    text-align: left;
    color: #7642E3;
    /* margin-left: 20px; */
    text-align: center;
    padding: 0 10px;
    text-transform: uppercase;

}



.service-box p:nth-child(2) {
    margin-top: 28px;
    width: 300px;
    font-family: Montserrat;
    font-size: 21px;
    font-weight: 300;
    line-height: 30px;
    text-align: left;
    color: #2C1858;
    margin-bottom: 30px;
    /* margin-left: 20px; */
   
}

.services-home-page {
    padding: 50px;
}

.service-wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 50px;
}

.service-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* width: 360px; */
    height: 250px;
    background-color: transparent;
    border: 1px solid #7642E3;
    text-align: left;
    border-radius: 30px;
    transition: .5s;
}

.service-box:hover{
    background-color: #7642E3;
    
}
.service-box:hover p{
    color: white;
}
.partners-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 46px;
    margin-bottom: 100px;
}

.partners-home-page img {
    width: 179px;
    height: 92px;
    margin-right: 50px;
}

.partners-home-page img:last-child {
    margin-right: 0;
}

.header-txt {
    font-size: 62px;
    font-weight: 800;
    line-height: 83px;
    text-align: left;
    margin-bottom: 90px;
    text-align: center;
    justify-content: center;
    display: flex;
    gap: 15px;

}

.header-txt span {
    color: #7642E3;
}

.projects-box {
    display: flex;
    justify-content: space-between;
    padding-inline: 40px;
    position: relative;
    
    
}


.projectinbox1,
.projectinbox2 {
    padding: 20px;
    width: 325px;
    min-height: 357px;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease, z-index 0.3s ease;
    overflow: hidden;
    /* cursor: pointer; */
    position: relative;
    z-index: 1;
}

.projectinbox2 {
    width: 437px;
    height: 481px;
}

.projectinbox1 img,
.projectinbox2 img {
    width: 100%;
    height: 160px;
    transition: transform 0.3s ease;
    transition: .5s;
    overflow: hidden;
}
.projectinbox1 img:hover,
.projectinbox2 img:hover{
    transform: scale(1.1);
}

.projectinbox2 img {
    width: 100%;
    height: 215px;
}

.projectinbox1 p:nth-child(2),
.projectinbox2 p:nth-child(2) {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
    transition: font-size 0.3s ease;
    
}

.projectinbox1 p:nth-child(3),
.projectinbox2 p:nth-child(3) {
    margin-top: 15px;
    color: #757575;
    transition: font-size 0.3s ease;
    line-height: 22px;
}

.projectinbox2 p:nth-child(2) {
    font-size: 30px;
}

.projectinbox2 p:nth-child(3) {
    font-size: 21px;
}

.home-page-projects {
    margin-bottom: 100px;
}

.home-page-packages {
    position: relative;
    background-image: url("../../assets/images/packages.png");
    background-position: center;
    background-repeat: no-repeat;
    height: 240vh;
}

.packages-txt {
    position: absolute;
    top: 35%;
    left: 30%;
}

.package-item {
    -webkit-box-shadow: 6px 10px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 6px 10px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 6px 10px 5px 0px rgba(0, 0, 0, 0.75);
    background-color: white;
    border-radius: 5px;
    width: 314px;
    height: 463px;
    border: 1px solid #D9D9D9;
    padding: 32px 32px 102px 32px;
}

.package-item p:first-child {
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 21px;
    color: #1E1E1E;
}

/* .package-item p:nth-child(2) {
    font-size: 62px;
    font-weight: 600;

    span {
        font-size: 18px;
        font-weight: 400;
    }
} */

.package-item ul {
    padding-left: 20px;
    list-style-type: disc;
}

.package-item li {
    margin-block: 12px;
    color: #757575;
}

.package-item button {
    cursor: pointer;
    margin-block: 32px;
    width: 250px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #606060;
}

.packages-box {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-inline: 120px;
    position: absolute;
    top: 45%;
}

.package2button {
    background-color: #1E1E1E;
    color: white;
}
@media (min-width: 768px) and (max-width:1024px){
    .service-wrap{
        grid-template-columns: repeat(2,1fr);
    } 
    .projects-box{
        flex-direction: column;
        row-gap: 20px;
        align-items: center;
        justify-content: center;
        width: 80%;
        margin: 0 auto;
    }
    .projectinbox1,.projectinbox2{
        width: 100%;
    }
    .projectinbox1 img ,.projectinbox2 img{
        width: 100%;
    }
    .containerDigigo{
        grid-template-columns: 1fr;
    }
    .services-home-page{
        margin-top: 165px;
    }
}
@media (max-width: 768px) {
    .header-txt{
        font-size: 30px !important;
    }
    .left-side p:first-child{
        font-size: 30px;
    }
    .left-side p:nth-child(2){
        font-size: 20px;
    }
    .services-home-page{
        padding: 0;
        padding-top: 80px;
        margin-top: 165px;
        width: 80%;
        margin: 0 auto;
    }
    .containerDigigo{
        grid-template-columns: 1fr;
    }
    .service-wrap{
        grid-template-columns: 1fr;
    }
    .projects-box{
        flex-direction: column;
        row-gap: 20px;
        align-items: center;
        justify-content: center;
    }
    .projectinbox1,.projectinbox2{
        width: 100%;
    }
    .projectinbox1 img ,.projectinbox2 img{
        width: 100%;
    }
}
