@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
.container{
    width: 100%;
    height: auto;
    margin: 0 auto;
    box-sizing: border-box;
}
.project-container {
    width: 100%;
    margin-top: 160px;
}
* {
    box-sizing: border-box;
}
.project-header p:first-child {
    font-size: 62px;
    font-weight: 800;
    line-height: 83px;
    text-align: center;
    margin-bottom: 25px;
    color: #2C1858;
}

.project-header span {
    color: #7642E3;
}

.project-header p:nth-child(2) {
    width: 800px;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
}

.project-header {
    margin-top: 94px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 103px;
}

.projects-headline {
    justify-content: space-between;
    /* padding: 96px 95px; */
    display: flex;
    width: 90%;
    margin: 0 auto;
    height: 672px;
    gap: 40px;
}

.projects-headline img {
    width: 592px;
    height: 480px;
}

.projects-headline div:nth-child(2) {
    width: 592px;
    height: 288px;
    /* padding: 0 48px;  */
}

.projects-headline p:first-child {
    font-family: "Inter", sans-serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -0.025em;
    text-align: left;
    color: #111827;
    margin-bottom: 32px;
}

.projects-headline p:nth-child(2) {
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    width: 477px;
    margin-bottom: 32px;
}

.projects-headline button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #7642E3;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;

}
@media (min-width: 768px) and (max-width:1024px){
    .project-header{
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .project-header p:first-child {
        font-size: 48px;
        line-height: 60px;
        margin-bottom: 20px;
    }

    .project-header p:nth-child(2) {
        width: 100%;
        padding: 0 20px;
        font-size: 16px;
        line-height: 24px;
    }

    .projects-headline {
        flex-direction: column;
        align-items: center;
        padding: 50px 20px;
        height: auto;
    }

    .projects-headline img {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }

    .projects-headline div:nth-child(2) {
        width: 100%;
        height: auto;
        padding: 0 20px;
        text-align: center; 
    }

    .projects-headline p:first-child {
        font-size: 36px;
        line-height: 48px;
        text-align: center;
        margin-bottom: 20px;
    }

    .projects-headline p:nth-child(2) {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
    }

    .projects-headline button {
        font-size: 14px;
        line-height: 20px;
        margin: 0 auto; 
        display: block;
    }
}
@media (max-width: 768px) {
    .project-header{
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .project-header p:first-child {
        font-size: 36px;
        line-height: 46px;
        margin-bottom: 15px;
    }

    .project-header p:nth-child(2) {
        width: 90%;
        padding: 0 10px;
        font-size: 14px;
        line-height: 20px;
    }

    .projects-headline {
        flex-direction: column;
        align-items: center;
        padding: 30px 10px;
        height: auto;
    }

    .projects-headline img {
        width: 100%;
        height: 300px;
        margin-bottom: 15px;
    }

    .projects-headline div:nth-child(2) {
        width: 100%;
        height: auto;
        padding: 0 10px;
        text-align: center; 
    }

    .projects-headline p:first-child {
        font-size: 28px;
        line-height: 38px;
        text-align: center;
        margin-bottom: 15px;
    }

    .projects-headline p:nth-child(2) {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        width: 100%;
        margin-bottom: 15px;
    }

    .projects-headline button {
        font-size: 14px;
        line-height: 20px;
        margin: 0 auto;
        display: block;
    }
}

