.footer {

    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    height: 327px;
    display: flex;
    justify-content: center;
    padding: 0 20px;
    align-items: center;
    box-sizing: border-box;


}

.footer-left-side {
    /* flex: 1;  */
    padding: 70px 40px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
}

.footer-left-side p:first-child {
    font-size: 40px;
    font-weight: 900;
    line-height: 34px;
    color: #2C1858;
    margin-bottom: 61px;
}

.footer-left-side p:nth-child(2) {
    color: #B5C1D4;
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 28px;
}

.footer-left-side a {
    cursor: pointer;
    text-decoration: underline;
    color: #B5C1D4;
}

.footer-icons {
    
    display: flex;
    flex: 1; 
    justify-content: flex-start; 
}

.footer-icon {
    cursor: pointer;
    margin-right: 23px;
    font-size: 24px;
}

.footer-right-side {
    display: block;
    flex: 1; 
    padding: 70px 20px;
    display: flex;
}

.footer-column {
    display: flex;
    flex-direction: column;
    margin-right: 63px;
}

.footer-column p {
    margin-bottom: 12px;
    font-family: Montserrat, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0.1em;
    color: #6D819E;
}

.footer-column a {
    margin-bottom: 12px;
    text-decoration: none;
}
@media (min-width: 768px) and (max-width: 1024px) {
    .footer-right-side {
        display: none;
    }
    .footer {
        justify-content: center;
    }
    .footer-left-side {
        margin: 0 auto;
        text-align: center;
    }
    .footer-icons {
        justify-content: center;
    }
}
@media (min-width: 375px) and (max-width: 768px) {
    .footer-right-side {
        display: none;
    }
    .footer {
        justify-content: center;
    }
    .footer-left-side {
        margin: 0 auto;
        text-align: center;
    }
    .footer-icons {
        justify-content: center;
    }
}