@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.contact-page{
    width: 100%;
}
.contact-header p:first-child {
    font-size: 62px;
    font-weight: 800;
    line-height: 83px;
    text-align: center;
    margin-bottom: 25px;

}

.contact-header span {
    color: #7642E3;
}

.contact-header p:nth-child(2) {
    width: 800px;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
   

}

.contact-header {
    margin-top: 94px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 109px;
}

.contact-details {
    width: 571px;
}
.contact-info{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contact-details p:first-child {
    text-align: center;
    font-size: 50px;
    font-weight: 800;
    line-height: 83px;
}

.contact-details span {
    color: #7642E3;
}

.contact-details p:nth-child(2) {
    /* width: 464px; */

    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
}

.contact-icon {
    margin-right: 20px;
    width: 36px;
    height: 44px;
    color: #7642E3;
}

.contact-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 48px;
}

.contact-description {
    text-align: center;
    margin-bottom: 93px;
}

.contact-content{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.contact-form{
    display: flex;
    flex-direction: column;
    width: 300px;
}
.contact-form-wrapper p:first-child {
    display: none;
    text-align: center;
    font-size: 50px;
    font-weight: 800;
    line-height: 83px;
    margin-bottom: 20px;
    margin-top: 30px;
}

.contact-form-wrapper span {
    color: #7642E3;
}
#firstName,
#lastName,
#email,
#message {
    font-family: "Inter", sans-serif;
    font-size: 17.65px;
    font-weight: 400;
    line-height: 24.7px;
    text-align: left;
}
.form-input{
    padding-inline: 10px;
    margin-top: 9px;
    height: 35px;
    border-radius: 9px;
    border: 1.1px solid #D9D9D9;
    margin-bottom: 27px;
}
.form-textarea{
    padding-inline: 10px;
    margin-top: 9px;
    border-radius: 9px;
    border: 1.1px solid #D9D9D9;
}
.form-submit-btn{
    cursor: pointer;
    margin-top: 27px;
    border-radius: 9px;
    border:1.1px solid #D9D9D9 ;
    background-color: black;
    color: white;
    height: 42px;
}
.contact-text{
    min-width: 200px;
    max-width: 250px;
    font-size: 22px;
    text-decoration: none;
    transition: .5s;
    color: black;

}
.contact-text:hover{
    color: #7642E3;
}
@media (min-width: 768px) and (max-width:1024px){
    .form-submit-btn{
        background-color: #7642E3;
    }
    .contact-form-wrapper p:first-child {
        display: block;
        text-align: center;
        font-size: 50px;
        font-weight: 800;
        line-height: 83px;
        margin-bottom: 20px;
        margin-top: 30px;
    }
    .contact-details p:first-child {
        display: none; 
    }
    .contact-description{
        display: none;
    }
    .contact-text{
        text-align: center;
       
    }
    
    .contact-header {
        margin-top: 50px; 
        margin-bottom: 50px; 
    }

    .contact-header p:first-child {
        font-size: 48px; 
    }

    .contact-header p:nth-child(2) {
        width: 90%; 
        font-size: 16px;
    }

    .contact-details {
        width: 90%; 
        margin: 0 auto;
    }

    .contact-item {
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;
        row-gap: 20px;
    }

    .contact-content {
        flex-direction: column; 
        align-items: center; 
    }

    .contact-form {
        width: 90%; 
        margin: 0 auto; 
    }
    
    #firstName,
    #lastName,
    #email,
    #message {
        width: 100%; 
    }
}
@media  (max-width: 768px) {
    .contact-header {
        margin-top: 50px; 
        margin-bottom: 50px; 
    }

    .contact-header p:first-child {
        font-size: 40px; 
        text-align: center;
    }

    .contact-header p:nth-child(2) {
        width: 90%; 
        font-size: 14px; 
        text-align: center;
    }

    .contact-details {
        width: 90%; 
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .contact-details p:first-child {
        font-size: 36px; 
        text-align: center;
    }

    .contact-details p:nth-child(2) {
        width: 100%; 
        text-align: center; 
        font-size: 16px;
    }

    .contact-description {
        display: none; 
    }

    .contact-item {
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;
        row-gap: 15px;
    }

    .contact-content {
        flex-direction: column; 
        align-items: center; 
    }

    .contact-form {
        width: 90%; 
        margin: 0 auto; 
    }

    .contact-form-wrapper p:first-child {
        display: block;
        text-align: center;
        font-size: 36px; 
        font-weight: 800;
        line-height: 50px;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .form-input,
    .form-textarea {
        width: 100%; 
        padding: 10px;
        margin-top: 9px;
        height: 35px;
        border-radius: 9px;
        border: 1.1px solid #D9D9D9;
        margin-bottom: 20px;
    }

    .form-submit-btn {
        background-color: #7642E3;
        color: white;
        height: 42px;
        margin-top: 20px;
        width: 100%;
        border-radius: 9px;
    }
}
