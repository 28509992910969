.navbar-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    background-color: transparent;
}

.logo-digigo {
    width: 82.62px;
    height: 82.09px;
}

.logo-block {
    margin-top: 25px;
    margin-left: 75px;
    margin-right: 37px;
}

.menu-elements a.active {
    color: #757575;
}

.menu-elements {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
}

.menu-items {
    display: flex;
    justify-content: center;
    flex-grow: 1;
}

.menu-elements a {
    text-decoration: none;
    color: #2C3746;
    font-weight: 600;
    font-size: 16px;
    margin: 0 40px;
}

.language {
    display: flex;
    align-items: center;
    margin-right: 50px;
    margin-left: 50px;
}

.language a {
    margin: 0;
}

.burger-menu {
    display: none;
    cursor: pointer;
    font-size: 30px;
    margin-right: 20px;
}

@media (min-width: 768px) and (max-width: 1024px) {
    
    .logo-digigo {
        width: 90px;
        height: 90px;
    }
    .menu-elements {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 110px;
        left: 0;
        width: 100%;
        background-color: white;
        padding: 20px;
    }

    .menu-items {
        display: flex; 
        flex-direction: column;
        width: 100%; 
    }

    .menu-elements a {
        margin: 10px 0; 
        padding: 10px; 
        text-align: center; 
    }

    .burger-menu {
        display: block;
    }

    .menu-active {
        display: flex;
        z-index: 99;
    }
}
@media  (max-width: 768px) {
    
    .logo-digigo {
        width: 56px;
        height: 56px;
    }
    .menu-elements {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 100px;
        left: 0;
        width: 100%;
        background-color: white;
        padding: 20px;
    }

    .menu-items {
        display: flex; 
        flex-direction: column;
        width: 100%; 
    }

    .menu-elements a {
        margin: 10px 0; 
        padding: 10px; 
        text-align: center; 
    }

    .burger-menu {
        display: block;
    }

    .menu-active {
        display: flex;
        z-index: 99;
    }
}