@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.services-container {
    width: 100%;
    overflow-x: hidden; 
}

.services-header p:first-child {
    font-size: 62px;
    font-weight: 800;
    line-height: 83px;
    text-align: center;
    margin-bottom: 25px;

}

.services-header span {
    color: #7642E3;
}

.services-header p:nth-child(2) {
    width: 800px;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;

}

.services-header {
    margin-top: 94px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 113px;
}

.service-item {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    justify-content: center;
    align-items: center;
    /* width: 401px; */
    /* height: 160px; */
    text-transform: uppercase;
}
.service-row .service-item:not(:nth-child(3)):not(:nth-child(6)) {
    margin-right: 30px;
}


.service-item p:nth-child(3) {
    font-family: "Inter", sans-serif;
    font-size: 16.74px;
    font-weight: 400;
    line-height: 25.11px;
    text-align: center;

}

.service-item img {
    width: 45px;
    height: 45px;
    top: 1.39px;
    left: 1.4px;}

.service-item p:nth-child(2) {
    font-family: "Inter", sans-serif;
    font-size: 31px;
    font-weight: 400;
    line-height: 41.85px;
    text-align: center;
}
.service-row{
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-bottom: 104px;
    width: 100%;
}
.services-content{
    /* display: flex; */
    /* flex-direction: column; */
    display: grid;
    grid-template-columns: repeat(3,1fr);
    align-items: center;
    row-gap: 80px;
    justify-content: center;
    padding: 0 40px; 
    margin-bottom: 330px;
    box-sizing: border-box;
}
@media (min-width: 768px) and (max-width:1024px) {
    .services-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .services-header {
        margin-top: 50px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .services-header p:first-child {
        font-size: 48px;
        line-height: 60px;
        margin-bottom: 20px;
        width: 90%; 
    }

    .services-header p:nth-child(2) {
        width: 80%; 
        font-size: 16px;
        line-height: 24px;
        padding: 0 20px;
    }

    .service-row {
        flex-direction: column;
        gap: 20px;
        margin-bottom: 50px;
        align-items: center;
    }

    .service-item {
        width: 100%;
        max-width: 300px;
        height: auto;
    }

    .services-content {
        padding: 0 20px;
        margin-bottom: 100px;
        width: 90%;
        margin: 0 auto;
    }
}
@media (max-width: 768px)  {
    .services-content{
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;
    }
    .services-header {
        margin-top: 30px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .services-header p:first-child {
        font-size: 36px;
        line-height: 46px;
        margin-bottom: 15px;
        width: 95%; 
    }

    .services-header p:nth-child(2) {
        width: 90%; 
        font-size: 14px;
        line-height: 20px;
        padding: 0 10px;
    }

    .service-row {
        flex-direction: column;
        gap: 15px;
        margin-bottom: 30px;
        align-items: center;
    }

    .service-item {
        width: 100%;
        max-width: 280px; 
        height: auto;
    }

    .services-content {
        padding: 0 10px;
        margin-bottom: 50px;
    }
}
