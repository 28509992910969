.about-container {
    width: 100%;
    margin-top: 160px;
}

.about-header p:first-child {
    font-size: 62px;
    font-weight: 800;
    line-height: 83px;
    text-align: center;
    margin-bottom: 25px;
    color: #2C1858;
}

.about-header span {
    color: #7642E3;
}

.about-header p:nth-child(2) {
    width: 800px;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
}

.about-header {
    margin-top: 94px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 103px;
}

.about-us-circles {
    display: grid;
        grid-template-columns: repeat(3,1fr);
        column-gap: 15px;
        row-gap: 20px;
    /* margin-bottom: 98px; */
    padding-bottom: 90px;
    width: 90%;
    margin: 0 auto;
}


.circle-image {
    width: 225px;
    height: 225px;
    border-radius: 50%;
    overflow: hidden;
    margin: 10px;
    background-color: #D9D9D9;
}

.circle-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.circle-box {
    /* width: 225px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-right: 100px;  */
    /* margin-bottom: 20px; */
    text-align: center;
    text-transform: uppercase;
}

.row .circle-box:nth-child(3n) {
    margin-right: 0;
}
.circle-box p:nth-child(2) {
    font-family: "Poppins", sans-serif;
    font-size: 37px;
    font-weight: 700;
    line-height: 55.5px;
    text-align: center;
    color: #7642E3;
}

.circle-box p:nth-child(3) {
    font-family: "Poppins", sans-serif;
    font-size: 25px;
    font-weight: 700;
    line-height: 37.5px;
    text-align: center;
    color: #470179;
}


@media (min-width: 768px) and (max-width: 1024px) {
    .about-header {
        text-align: center;
    }

    .about-us-circles {
        grid-template-columns: repeat(2,1fr);
    }

    /* .row {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        align-items: center;
        width: 100%;
        
    } */

    .circle-box {
        margin-right: 0; 
        margin-bottom: 20px;
        text-align: center;
    }

    .circle-image img {
        /* max-width: 100px; */
        height: auto;
    }
}
@media (max-width: 768px) {
    .about-container {
        padding: 0 20px;
    }

    .about-header p:first-child {
        font-size: 42px; 
        line-height: 52px;
        margin-bottom: 15px;
    }

    .about-header p:nth-child(2) {
        width: 100%; 
        font-size: 16px; 
        line-height: 24px;
    }

    .about-header {
        text-align: center;
        margin-bottom: 50px;
    }

    .about-us-circles {
      grid-template-columns: repeat(2,1fr);
        /* margin-bottom: 50px; */
    }

    .circle-box {
        margin-right: 0; 
        margin-bottom: 15px;
        text-align: center;
    }

    .circle-image {
        width: 120px; 
        height: 120px;
    }

    .circle-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .circle-box p:nth-child(2) {
        font-size: 28px;
        line-height: 38px;
    }

    .circle-box p:nth-child(3) {
        font-size: 20px; 
        line-height: 30px;
    }
}
